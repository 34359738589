<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline
        class="col-xl-12"
        :headline="$t('global.appointments') + ' (' + count + ')'"
        :col="3"
      >
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button
              class="btn btn-success"
              @click="showNeuerTermin"
              v-if="berechtigungen.m_ressourcen.create"
            >
              <font-awesome-icon icon="fa-regular fa-plus" />
              {{ $t("global.new") }}
            </button>
            <button
              class="btn btn-primary ml-2"
              @click="raumUpdaten"
              v-if="
                $CONST('CONFIG').APPTYPE != 'DESKA' &&
                berechtigungen.m_ressourcen.update
              "
            >
              <font-awesome-icon icon="fa-duotone fa-door-open" class="mr-1" />
              {{ $t("global.changeroom") }}
            </button>
            <button
              class="btn btn-primary ml-2"
              @click="dozenteneinsatzErstellen"
              v-if="
                $CONST('CONFIG').APPTYPE != 'DESKA' &&
                berechtigungen.m_ressourcen.update
              "
            >
              <font-awesome-icon icon="fa-duotone fa-user-clock" />
              {{ $t("global.createlecturerassignment") }}
            </button>
            <massenmutation-button
              class="mr-2"
              style="display: inline-flex"
              :selectedIds="selectedIds"
              :propEntities="propEntities"
              :modalId="'massenmutationsmodal-terminreiter'"
              @mutated="refresh"
            />
          </div>
          <div class="mr-4">
            <button
              class="btn btn-danger"
              @click="oeffneLoeschenModal"
              v-if="berechtigungen.m_ressourcen.delete"
            >
              <font-awesome-icon class="mr-2" icon="fa-duotone fa-trash" />
              <span>{{ $t("global.delete") }}</span>
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="termine"
              :fields="computedFields"
              :busy="isBusy"
              show-empty
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              @row-dblclicked="details"
              :sort-compare="sortCompare"
              thead-tr-class="pointer"
            >
              <template v-slot:cell(anwesenheit)="row">
                <b-button size="sm" @click="AnwesenheitBearbeiten(row.item.id)">
                  <font-awesome-icon icon="fa-regular fa-pencil" />
                </b-button>
              </template>

              <template v-slot:head(selected)="row">
                <b-form-checkbox
                  v-model="row.selected"
                  @input="selectAllRows(row)"
                ></b-form-checkbox>
              </template>

              <template v-slot:cell(selected)="row">
                <b-form-checkbox
                  v-model="row.item.selected"
                  @input="onRowSelected(row)"
                ></b-form-checkbox>
              </template>

              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>{{ $t("global.errorwhileloading") }}</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>{{ $t("global.nodataavailable") }}</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <termin-raum-update-modal
      :termine="selectedIds"
      @updated="raeumeGeaendert"
    />

    <termin-dozenteneinsatz-update-modal
      :termine="Array.from(new Set(this.selectedIds))"
      :dozentenbuchungen="dozentenbuchungen"
      @updated="deUpdated"
    />

    <neuer-termin-modal
      :id="'neuer-termin-modal-' + veranstaltung.id"
      :veranstaltung="veranstaltung"
      :dozentenbuchungen="dozentenbuchungen"
      :raum="raum"
      :planungsstati="planungsstati"
      :durchfuehrungsstati="durchfuehrungsstati"
      :anzahl_anmeldungen="anzahl_anmeldungen"
      @confirmed="getTermine"
    ></neuer-termin-modal>

    <loeschen-modal
      id="termin-loeschen-modal"
      @confirmed="terminloeschen"
      :multiple="true"
    />
  </div>
</template>

<script>
import Api from "@/Api";
import { apiErrorToAlert } from "@/utils/Errorhandler";
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import { LADE_AUSWAHLWERTE_DOZENT } from "@/store/dozent/actions.type";
import NeuerTerminModal from "@/components/Modals/NeuerTerminModal";
import LoeschenModal from "@/components/Modals/LoeschenModal";
import TerminRaumUpdateModal from "@/components/Modals/TerminRaumUpdateModal.vue";
import TerminDozenteneinsatzUpdateModal from "@/components/Modals/TerminDozenteneinsatzUpdateModal.vue";
import MassenmutationButton from "@/components/Buttons/MassenmutationButton";

export default {
  components: {
    ContainerHeadline,
    NeuerTerminModal,
    LoeschenModal,
    TerminRaumUpdateModal,
    TerminDozenteneinsatzUpdateModal,
    BSpinner,
    MassenmutationButton,
  },

  props: {
    veranstaltung: {
      type: Object,
      required: false,
    },
    raum: {
      type: String,
      required: false,
    },
    arbeitsplatz: {
      type: String,
      required: false,
    },
    shown: {
      type: Boolean,
      required: false,
    },
    terminlisteKey: {
      type: Number,
    },
    anzahl_anmeldungen: {
      type: Number,
    },
  },
  data() {
    return {
      page: 0,
      count: 0,
      infiniteId: +new Date(),
      anzahlProPage: 100,
      loading: false,
      isBusy: false,
      ladeFehler: false,
      termine: [],
      dozentenbuchungen: [],
      langtextLoopCount: null,
      selected: [],
      selectedIds: [],
      NeuerTerminModalId: "neuer-termin-modal",
      propEntities: [
        {
          tbl_name: "bxc_termin",
          selectedIds: [],
        },
      ],
    };
  },
  watch: {
    shown(val) {
      if (val && this.termine.length == 0) this.getTermine();
      if (
        val &&
        (this.durchfuehrungsstati.length == 0 || this.planungsstati.length == 0)
      )
        this.$store.dispatch(`dozent/${LADE_AUSWAHLWERTE_DOZENT}`);
    },

    lektionenTotal: function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.$bus.$emit("lektionenTotal", newValue);
      }
    },
    terminlisteKey: function () {},
    veranstaltung(newVal) {
      if (newVal !== null) {
        this.getTermine();
        this.getDozentenBuchungen();
      }
    },
  },
  computed: {
    fieldsDESKA() {
      return [
        { key: "selected", label: "" },
        {
          key: "von",
          sortable: true,
          label: this.$t("global.from"),
        },
        { key: "bis", sortable: true, label: this.$t("global.till") },
        { key: "von_uhrzeit", sortable: true, label: this.$t("global.start") },
        { key: "bis_uhrzeit", sortable: true, label: this.$t("global.end") },
        {
          key: "reserviert_durch.personName",
          sortable: true,
          label: this.$t("global.reservedfor"),
        },
      ];
    },
    fieldsEducation() {
      return [
        { key: "selected", label: "" },
        {
          key: "von",
          sortable: true,
          label: this.$t("global.from"),
        },
        { key: "von_uhrzeit", sortable: true, label: this.$t("global.TIme") },
        { key: "bis", sortable: true, label: this.$t("global.till") },
        { key: "bis_uhrzeit", sortable: true, label: this.$t("global.TIme") },
        {
          key: "lektionen",
          sortable: true,
          label: this.$t("global.lessons"),
        },
        {
          key: "bezeichnung",
          sortable: true,
          label: this.$t("global.designation"),
        },
        {
          key: "gebaeude.bezeichnung",
          sortable: true,
          label: this.$t("global.building"),
        },
        {
          key: "raeumeleitsystem",
          sortable: true,
          label: this.$t("global.roommanagementsystem"),
        },
        {
          key: "verantwortlich",
          sortable: true,
          label: this.$t("global.responsible"),
          formatter: (value) => {
            if (value) return value.vorname + " " + value.name;
          },
        },
        {
          key: "anzahl_anmeldungen",
          sortable: true,
          label: this.$t("global.participants"),
        },
        {
          key: "anwesenheit",
          sortable: true,
          label: this.$t("global.presence"),
        },
      ];
    },
    computedFields: function () {
      return this.$CONST("CONFIG").APPTYPE == "DESKA"
        ? this.fieldsDESKA
        : this.fieldsEducation;
    },
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      if (this.suche) params.suche = this.suche;

      return params;
    },
    durchfuehrungsstati: {
      get() {
        return this.$store.state.dozent.durchfuehrungsstati;
      },
    },
    planungsstati: {
      get() {
        return this.$store.state.dozent.planungsstati;
      },
    },

    lektionenTotal: function () {
      let summeLektionen = 0;
      this.termine.forEach((el) => {
        summeLektionen += el.lektionen;
      });
      return summeLektionen;
    },
  },
  mounted() {
    if (this.$CONST("CONFIG").APPTYPE === "DESKA") {
      this.setHeadersTerminliste("global", [
        "",
        "from",
        "till",
        "start",
        "end",
        "reservedfor",
      ]);
    } else {
      this.setHeadersTerminliste("global", [
        "",
        "from",
        "TIme",
        "till",
        "TIme",
        "lessons",
        "roommanagementsystem",
        "responsible",
        "participants",
        "presence",
      ]);
    }

    if (this.shown && this.termine.length == 0) {
      this.getTermine();
      this.getDozentenBuchungen();
      if (
        this.durchfuehrungsstati.length == 0 ||
        this.planungsstati.length == 0
      ) {
        this.$store.dispatch(`dozent/${LADE_AUSWAHLWERTE_DOZENT}`);
      }
    }
    //this.$bus.$emit("lektionenTotal", this.lektionenTotal);

    this.$bus.$on("neuerTerminGespeichert", () => {
      this.getTermine();
    });
  },
  created() {
    // this.getDozentenBuchungen();
    this.$bus.$on("getDozentenbuchungen", (data) => {
      this.dozentenbuchungen = data;
    });
  },

  methods: {
    setHeadersTerminliste(prefix, headerTexts) {
      if (this.langtextLoopCount < 15) {
        setTimeout(
          function () {
            if (this.$store.state.langtexts.loaded == true) {
              let count = 0;
              headerTexts.forEach((element) => {
                if (element != "") {
                  if (this.$CONST("CONFIG").APPTYPE === "DESKA") {
                    this.fieldsDESKA[count].label =
                      this.$store.state.langtexts.texts[this.$i18n.locale][
                        prefix
                      ][element];
                  } /* else {
                    this.fieldsEducation[count].label =
                      this.$store.state.langtexts.texts[this.$i18n.locale][
                        prefix
                      ][element];
                  } */
                }
                count++;
              });
            } else {
              this.langtextLoopCount++;
              this.setHeadersTerminliste(prefix, headerTexts);
            }
          }.bind(this),
          500
        );
      }
    },

    sortCompare(itemA, itemB, key) {
      if (key !== "von" && key !== "bis") {
        return false;
      } else {
        let a = itemA[key];
        let b = itemB[key];

        a = a.split(".");
        b = b.split(".");

        a =
          parseInt(a[2], 10) * 10000 +
          parseInt(a[1], 10) * 100 +
          parseInt(a[0]);
        b =
          parseInt(b[2], 10) * 10000 +
          parseInt(b[1], 10) * 100 +
          parseInt(b[0]);

        return a - b;
      }
    },
    raeumeGeaendert() {
      this.selectedIds = [];
      this.getTermine();
    },

    deUpdated() {
      this.selectedIds = [];
      this.$emit("updated");
    },

    raumUpdaten() {
      this.$bus.$emit("openTerminRaumModal");
    },

    dozenteneinsatzErstellen() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openTerminDozenteneinsatzModal");
      else {
        this.$notify({
          type: "warn",
          title: this.$t("global.actionfailed"),
          text: this.$t("global.noappointmentsselected"),
        });
      }
    },

    getTermine() {
      if (!this.veranstaltung?.id) return;
      this.loading = true;
      this.isBusy = true;

      Api.get("termin/", {
        params: {
          veranstaltung: this.veranstaltung.id,
          raum: this.raum,
          arbeitsplatz: this.arbeitsplatz,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.termine = response.data;
          this.count = this.termine.length;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
          this.loading = false;
        });
    },

    getDozentenBuchungen() {
      if (!this.veranstaltung?.id) return;
      this.isBusy = true;

      Api.get("dozentenbuchung/", {
        params: {
          veranstaltung: this.veranstaltung.id,
          page: this.page,
          count: this.anzahlProPage,
        },
      })
        .then((response) => {
          this.dozentenbuchungen = response.data;
          this.ladeFehler = false;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
          this.ladeFehler = true;
        })
        .finally(() => {
          this.isBusy = false;
        });
    },

    AnwesenheitBearbeiten(termin) {
      this.$router.push({
        name: "anwesenheit",
        params: {
          vid: this.veranstaltung ? this.veranstaltung.id : null,
          tid: termin,
        },
      });
    },

    details(termin) {
      this.$router.push({
        name: "termin",
        params: { id: termin.id, anzeige: 0 },
      });
    },

    infiniteHandler($state) {
      if (this.shown) {
        this.loading = true;
        Api.get("termin/", {
          params: this.queryParams,
        }).then((response) => {
          if (response.data.length === this.anzahlProPage) {
            this.page += 1;
            this.termin.push(...response.data);
            $state.loaded();
          } else {
            this.termin.push(...response.data);
            $state.complete();
          }
        });
        this.loading = false;
      }
    },

    selectAllRows(row) {
      if (row.selected) {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = true;
          if (!this.selectedIds.includes(gp.id)) {
            this.selectedIds.push(gp.id);
          }
        });
      } else {
        this.$refs.selectableTable.items.forEach((gp) => {
          gp.selected = false;
        });
        this.selectedIds = [];
      }
      this.$forceUpdate();
    },

    onRowSelected(row) {
      if (row.item.selected) {
        this.selectedIds.push(row.item.id);
      } else {
        this.selectedIds = this.selectedIds.filter((id) => id != row.item.id);
      }
    },

    showNeuerTermin() {
      this.$bus.$emit(
        "openNeuerTerminModal",
        `neuer-termin-modal-${this.veranstaltung?.id}`
      );
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "termin-loeschen-modal");
    },

    terminloeschen() {
      Api.delete("termin/", {
        params: { ids: this.selectedIds.join(",") },
      }).then(() => {
        this.getTermine();
        this.selectedIds = [];
      });
    },

    kopieren() {
      Api.get("termin/", { params: { id: this.selectedIds[0] } }).then(
        (response) => {
          response.data.verantwortlich = response.data.verantwortlich.id;
          response.data.reserviert_durch = response.data.reserviert_durch.id;
          response.data.veranstaltung = response.data.veranstaltung.id;
          response.data.raumliste = response.data.raumliste.map(
            (raum) => raum.id
          );

          Api.post("termin/", response.data)
            .then(() => {
              this.getTermine();
            })
            .finally(() => {
              this.selectedIds = [];
            });
        }
      );
    },
    refresh() {
      this.selectedIds = [];
      this.resetLoading(this);
    },
  },
};
</script>
